let key = {};
//empty string
if (process.env.REACT_APP_MODE === "production") {
}
else if (process.env.REACT_APP_MODE === "demo") {
    console.log("Set Demo Config")
    const API_URL = 'https://ryfin-backend.maticz.in/';
    // const API_URL = "https://backend-tvt.maticz.in/"

    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        RECAPTCHA_SITE_KEY: "6Lc0jA4jAAAAADmNtnnGw7Px86Pscz2sgpavPIcn", //local
        API_URL: API_URL,
        // API_URL: "https://backend-tvt.maticz.in/",
        FRONT_URL: 'https://ryfin.maticz.in/',
        ADMIN_URL: 'https://ryfinexchangeadmin.maticz.in/admin',
        SOCKET_URL: 'https://ryfin-backend.maticz.in/',
        getGeoInfo: "https://ipapi.co/json/",
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        },
        // GOOGLEFORMURL:"https://forms.gle/yeuZqbY9CrBXahw99"
        GOOGLEFORMURL: 'https://forms.gle/eNohwFmnDkR9PExb8'
    };
}
else {
    console.log("Set Development Config")
    // const API_URL = 'https://api.cluxchange.com/';
    const API_URL = 'http://localhost' //http://192.168.0.54' // 'http://localhost'// 'http://localhost' 'http://192.168.0.124';  //http://192.168.29.63:3000/  http://192.168.0.124
    const GOOGLEFORMURL = 'https://forms.gle/eNohwFmnDkR9PExb8'
    key = {
        secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
        CRYPTO_SECRET_KEY: "1234567812345678",
        RECAPTCHA_SITE_KEY: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
        API_URL: `${API_URL}:2053`,
        FRONT_URL: 'http://localhost:3000', //'http://localhost:3000',
        ADMIN_URL: 'http://localhost:3001/admin',
        SOCKET_URL: `${API_URL}:2053`,
        getGeoInfo: "https://ipapi.co/json/",
        AUTHENTICATOR_URL: {
            PLAY_STORE: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
            APP_STORE: "https://apps.apple.com/us/app/google-authenticator/id388497605",
        },
        // GOOGLEFORMURL:"https://forms.gle/yeuZqbY9CrBXahw99"
        GOOGLEFORMURL: 'https://forms.gle/eNohwFmnDkR9PExb8'
    };
}

export default {
    ...key,
    ...{ SITE_DETAIL: require('./siteConfig').default }
};